<template>
  <b-row>
    <b-col cols="12">
      <b-card title="Tải lên file">
        <b-form @submit.prevent="handleSubmit()">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Files"
                label-cols-md="2"
                label-for="h-images"
              >
                <b-form-file
                  id="h-image"
                  v-model="files"
                  drop-placeholder="Thả file tại đây..."
                  multiple
                  placeholder="Chọn 1 hoặc nhiều file..."
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Đường dẫn file"
                label-cols-md="2"
                label-for="h-images"
              >
                <b-form-textarea
                  id="post-summary"
                  v-model="uploadedPaths"
                  rows="20"
                  placeholder=""
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-col offset-md="6">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mr-1"
              @click="reset()"
            >
              Xóa
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="loading"
              class="mr-1"
              type="submit"
              variant="primary"
            >
              Tải lên
            </b-button>
          </b-col>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol, BRow, BCard, BForm, BFormGroup, BFormFile, BFormTextarea, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { FileUploadRepository } from '@core/resource/repository'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'FileUpload',
  components: {
    BRow, BCol, BCard, BForm, BFormGroup, BFormFile, BFormTextarea, BButton, ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      files: [],
      response: [],
      loading: false,
    }
  },
  computed: {
    uploadedPaths() {
      return this.response.join('\n')
    },
  },
  methods: {
    async handleSubmit() {
      this.loading = true
      this.response = []
      for (let i = 0; i < this.files.length; i += 1) {
        const file = this.files[i]
        const formData = new FormData()
        formData.append('files', file)
        const res = FileUploadRepository.post(formData).then(() => {
          this.response.push(`${process.env.APP_BASE_URL}/${res.data}`)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Uploaded',
              icon: 'BellIcon',
              text: file.name,
              variant: 'success',
            },
          })
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Upload failed',
              icon: 'BellIcon',
              text: file.name,
              variant: 'danger',
            },
          })
        })
      }
      this.loading = false
    },
    reset() {
      this.loading = false
      this.response = []
      this.files = []
    },
  },
}
</script>

<style>
</style>
