// eslint-disable-next-line import/no-extraneous-dependencies
import axiosIns from '@/libs/axios'

class Resource {
  constructor(uri) {
    this.uri = uri
  }

  getList(query) {
    return axiosIns({
      url: `/${this.uri}`,
      method: 'get',
      params: query,
    })
  }

  getOne(id) {
    return axiosIns({
      url: `/${this.uri}/${id}`,
      method: 'get',
    })
  }

  async post(body) {
    return await axiosIns({
      url: `/${this.uri}`,
      method: 'post',
      data: body,
    })
  }
}

export { Resource as default }
